import axios from 'axios'
import md5 from 'js-md5'
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.get['X-Requested-With'] = 'XMLHttpRequest'

let instance = axios.create({
	baseURL: 'https://campus.hsjt.group'
	// baseURL: 'http://192.168.0.114'
})

function createSigin() {
	let appid = '3b8c15dc55121ae7681f85a69b0cb401'
	let key = '06cdb79dea57419eb2176869a8bad022ec8e2b713155835e50f95d86a2e7927c'
	let timeout = parseInt(new Date().getTime() / 1000)
	return {
		appId: appid,
		timeOut: timeout
	}
}

function signStringify(obj) {
	var arr = []
	var params = ''

	for (var item in obj) {
		// 将所有非空参数(不为null,不为"",不为"   ")
		if (typeof obj[item] !== 'object') {
			if (
				obj[item] !== null &&
				!/^\s*$/.test(obj[item]) &&
				obj[item] != '' &&
				obj[item] != '0'
			) {
				if (typeof obj[item] === 'boolean') {
					params =
						item.toLowerCase() +
						'=' +
						(obj[item] ? 'true' : 'false')
				} else {
					params = item.toLowerCase() + '=' + obj[item]
				}
				arr.push(params)
			}
		}
	}
	arr.sort()
	return arr
}

instance.interceptors.request.use(
	config => {
		let Sign = ''
		let num = 0
		let obj = {
			appid: createSigin().appId,
			key: '06cdb79dea57419eb2176869a8bad022ec8e2b713155835e50f95d86a2e7927c',
			nowtime: createSigin().timeOut
		}
		if (config.data) {
			for (let i in config.data) {
				if (typeof config.data[i] == 'object') {
					break
				} else {
					obj[i] = config.data[i]
				}
			}
		}
		obj = signStringify(obj)
		for (let i in obj) {
			num++
			if (num < Object.getOwnPropertyNames(obj).length - 1) {
				Sign += `${obj[i]}` + 'SSJT&GW'
			} else {
				Sign += `${obj[i]}`
			}
		}
		// Header 带参数
		// config.headers.sign = md5(Sign).toUpperCase()
		// config.headers.nowtime = createSigin().timeOut
		// config.headers.appid = createSigin().appId
		return config
	},
	err => {
		return Promise.reject(err)
	}
)

instance.interceptors.response.use(
	response => {
		return response
	},
	err => {
		return Promise.reject(err)
	}
)

export default instance
